import React, { FC, ReactNode } from 'react';
import MoneyIcon from '../MoneyIcon';
import TruckIcon from '../TruckIcon';

type RoundedLabelProps = {
  variant: 'delivery' | 'freeShipping';
  additionalClassName?: string;
  children?: ReactNode;
};

const RoundedLabel: FC<RoundedLabelProps> = ({ variant, additionalClassName, children }) => {
  let variantClassName = '';
  if (variant === 'delivery') {
    variantClassName = 'bg-base-accent-1 text-neutral-5';
    if (!children) {
      children = <TruckIcon scale={0.67} />;
    }
  }
  if (variant === 'freeShipping') {
    variantClassName = 'bg-neutral-4 text-neutral-5';
    if (!children) {
      //scale 10x15 is the default scale for MoneyIcon
      children = <MoneyIcon scale={0.67} />;
    }
  }
  return (
    <div
      className={`flex items-center justify-center rounded-full px-3 py-4 ${variantClassName} ${additionalClassName}`}
    >
      {children}
    </div>
  );
};

export default RoundedLabel;

import React, { FC } from 'react';
import { ProductParameter } from 'helpers/utils/sortAttributes';

interface ProductParametersTableProps {
  productParamaters: ProductParameter[];
}

const ProductParametersTable: FC<ProductParametersTableProps> = ({ productParamaters }) => {
  return (
    <table className="w-full table-fixed">
      <tbody>
        {Object.entries(productParamaters).map(([key, attribute]) => (
          <tr key={key} className="border-b border-neutral-2 last:border-none">
            <td className="w-1/2 break-words border-r px-12 text-left text-14">{attribute.key}</td>
            <td className="w-1/2 px-16 py-12 text-left text-14">
              <p className="text-14">{attribute.value}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default ProductParametersTable;

function MoneyIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M13.75 12.712c-.699 0-1.29-.242-1.774-.726a2.413 2.413 0 01-.726-1.774c0-.7.242-1.29.726-1.774a2.413 2.413 0 011.774-.726c.699 0 1.29.242 1.774.726s.726 1.075.726 1.774c0 .698-.242 1.29-.726 1.774a2.413 2.413 0 01-1.774.726zm-6.596 3.096a1.74 1.74 0 01-1.277-.531A1.74 1.74 0 015.347 14V6.423c0-.497.176-.923.53-1.277a1.74 1.74 0 011.277-.53h13.192c.497 0 .923.176 1.277.53.354.354.53.78.53 1.277V14c0 .497-.176.923-.53 1.277a1.74 1.74 0 01-1.277.53H7.154zm1.5-1.5h10.192c0-.499.177-.925.531-1.278.354-.353.78-.53 1.277-.53V7.923a1.74 1.74 0 01-1.278-.53 1.743 1.743 0 01-.53-1.278H8.654c0 .5-.177.925-.531 1.278-.354.354-.78.53-1.277.53V12.5c.499 0 .925.177 1.278.531.353.354.53.78.53 1.277zm10.519 5H3.653a1.74 1.74 0 01-1.276-.531 1.74 1.74 0 01-.53-1.277V7.596h1.5V17.5c0 .077.031.148.095.212a.294.294 0 00.212.096h15.519v1.5zm-12.02-5h-.307V6.115h.308a.296.296 0 00-.217.092.296.296 0 00-.09.216V14c0 .083.03.155.09.216.061.061.133.092.217.092z"
      ></path>
    </svg>
  );
}

export default MoneyIcon;

import { FC } from 'react';
import { Attributes } from 'shared/types/product';
import AccordionBtn from 'components/commercetools-ui/atoms/accordion';
import { useFormat } from 'helpers/hooks/useFormat';
import sortAttributes from 'helpers/utils/sortAttributes';
import AttributesCategoryTable from './attributes-category-table';
import AttributesTable from './attributes-table';
import ProductParametersTable from './product-parameters-table';
import { UIProduct } from '../types';

type AdditionalInfoProps = {
  attributes?: Attributes;
  product: UIProduct;
};

const AdditionalInfo: FC<AdditionalInfoProps> = ({ attributes, product }) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { additionalInformation, productParameters, attributesCategory } = sortAttributes(attributes);
  const description = product.description || '';

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="additional-info col-span-2">
      <style>
        {`
        .additional-info ul {
          padding: 0 !important;
          list-style: inside circle;
        }

        .additional-info li {
          --tw-text-opacity: 1;
          color: rgb(33 33 33 / var(--tw-text-opacity));
          line-height: 150%;
          font-weight: 400;
          font-size: 14px;
      `}
      </style>

      {description && (
        <AccordionBtn
          className="col-span-2 mt-24 md:mt-0"
          closedSectionTitle={formatMessage({ id: 'product.desc', defaultMessage: 'Product Description' })}
          buttonClassName="pb-12 pt-20 border-b border-neutral-2 font-bold text-16 leading-tight text-neutral-4 pl-0 md:pr-12 mb-8 md:first:pl-12"
          panelClassName=" md:pt-10 text-neutral-4 px-20"
          as="h2"
        >
          <p
            className="font-body text-14 font-regular leading-loose text-neutral-4"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </AccordionBtn>
      )}
      {additionalInformation && (
        <AccordionBtn
          className="col-span-2 md:mt-0"
          closedSectionTitle={formatMessage({ id: 'details.additional', defaultMessage: 'Additional Details' })}
          buttonClassName="pb-12 pt-20 font-bold border-b border-neutral-2 text-16 leading-tight text-neutral-4 pl-0 md:pr-12 mb-8 md:first:pl-12"
          panelClassName="md:pt-10 text-neutral-4 md:px-12"
          as="h2"
        >
          <AttributesTable attributes={additionalInformation} product={product} />
        </AccordionBtn>
      )}

      {attributesCategory &&
        attributesCategory.map((attribute) => (
          <AccordionBtn
            key={attribute.localizedLabel}
            className="col-span-2 md:mt-0"
            closedSectionTitle={attribute.localizedLabel}
            buttonClassName="pb-12 pt-20 font-bold border-b border-neutral-2 text-16 leading-tight text-neutral-4 pl-0 md:pr-12 mb-8 md:first:pl-12"
            panelClassName="md:pt-10 text-neutral-4 md:px-12"
            as="h2"
          >
            <AttributesCategoryTable attributes={attribute.value} />
          </AccordionBtn>
        ))}

      {productParameters && (
        <AccordionBtn
          className="col-span-2 md:mt-0"
          closedSectionTitle={formatMessage({ id: 'details.parameters', defaultMessage: 'Product Parameters' })}
          buttonClassName="pb-12 pt-20 font-bold border-b border-neutral-2 text-16 leading-tight text-neutral-4 pl-0 md:pr-12 mb-8 md:first:pl-12"
          panelClassName="md:pt-10 text-neutral-4 md:px-12"
          as="h2"
        >
          <ProductParametersTable productParamaters={productParameters} />
        </AccordionBtn>
      )}
    </div>
  );
};

export default AdditionalInfo;

import React, { useMemo } from 'react';
import { Category } from 'shared/types/product/Category';
import Breadcrumb from 'components/commercetools-ui/atoms/breadcrumb';
import Link from 'components/commercetools-ui/atoms/link';

interface Props {
  categoryId?: string;
  categories: Category[];
}

const Breadcrumbs: React.FC<Props> = ({ categoryId, categories }) => {
  const currentCategory = useMemo(() => {
    return (categories.find((category) => category.categoryId === categoryId) ?? {}) as Category;
  }, [categories, categoryId]);

  const currentCategoryAncestors = useMemo(() => {
    if (!currentCategory.path) return [];

    const categoryIdChunks = currentCategory.path.split('?')[0].slice(1).split('/');

    const ancestorCategories = categoryIdChunks.map(
      (id) => (categories.find((category) => [category.categoryId, category.slug].includes(id)) ?? {}) as Category,
    );

    return ancestorCategories;
  }, [categories, currentCategory]);

  if (!categoryId) return <></>;

  return (
    <Breadcrumb isBreadcrumb>
      {currentCategoryAncestors.map((category) => (
        <Link key={category.categoryId} link={category.path} className="text-12">
          {category.name}
        </Link>
      ))}

      {currentCategory && (
        <Link key={currentCategory.categoryId} link={currentCategory.path} className="text-12">
          {currentCategory.name}
        </Link>
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;

import React, { FC } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { formatAttributeName } from 'helpers/utils/formatAttributeName';
import { Attribute } from 'helpers/utils/sortAttributes';
import EnergyLabel from '../../product-tile/components/energy-label';
import ProductCard, { ProductCardUrl } from '../../product-tile/components/product-card';
import { UIProduct } from '../types';

interface AttributesTableProps {
  attributes: Attribute[];
  product: UIProduct;
}

const AttributesTable: FC<AttributesTableProps> = ({ attributes, product }) => {
  const productCardUrl = product?.variants[0]?.attributes?.productCardUrl as ProductCardUrl;
  const { formatMessage } = useFormat({ name: 'product' });
  const energyLabel = {
    energyType: product?.variants[0]?.attributes?.energyClass?.value,
    energyURL: product?.variants[0]?.attributes?.energyLabelUrl?.value,
  };

  return (
    <table className="w-full table-fixed">
      <tbody>
        {Object.entries(attributes).map(([key, attribute]) => (
          <tr key={key} className="border-b border-neutral-2 last:border-none">
            <td className="w-1/2 break-words border-r px-12 text-14">
              {attribute.localizedLabel ?? formatAttributeName(key)}
            </td>
            <td className="w-1/2 px-16 py-12 text-left text-14">
              {Array.isArray(attribute?.value) && attribute.value.length > 1 ? (
                attribute.value.map((item) => <div key={item}>{item}</div>)
              ) : typeof attribute?.value === 'object' ? (
                Object.entries(attribute.value).map(([key, val]) => <div key={key}>{String(val)}</div>)
              ) : (
                <p className="text-14">{attribute.value}</p>
              )}
            </td>
          </tr>
        ))}
        {energyLabel?.energyType && (
          <tr className="border-b border-neutral-2 last:border-none">
            <td className="w-1/2 border-r px-12 text-left text-14">
              {product?.variants[0]?.attributes?.energyClass.localizedLabel}
            </td>
            <td className="w-1/2 px-16 py-12 text-left text-14">
              <EnergyLabel className="!relative !inset-y-0 " energyLabel={energyLabel} />
            </td>
          </tr>
        )}
        {productCardUrl?.value && (
          <tr className="border-b border-neutral-2 last:border-none">
            <td className="w-1/2 border-r px-12 text-left text-14">
              {productCardUrl?.localizedLabel ?? formatMessage({ id: 'productCard', defaultMessage: 'Product Card' })}
            </td>
            <td className="relative w-1/2 px-16 py-12 text-left text-14">
              <ProductCard productCardUrl={productCardUrl} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default AttributesTable;

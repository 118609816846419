import React, { FC } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import QuestionThinIcon from 'components/icons/QuestionThinIcon';
import { useFormat } from 'helpers/hooks/useFormat';

export interface ContactInfoProps {
  additionalClassName?: string;
}

const ContactInfoSection: FC<ContactInfoProps> = ({ additionalClassName }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <div className={`flex items-center gap-x-8 text-neutral-3 ${additionalClassName}`}>
      <QuestionThinIcon />
      <p className="text-14 leading-[17.5px]">
        {formatMessage({ id: 'contact.label', defaultMessage: 'Do you need larger quantities?' })}{' '}
        <Link className="underline" link="/account/?hash=support">
          {formatMessage({ id: 'contact.us', defaultMessage: 'Contact us' })}
        </Link>
      </p>
    </div>
  );
};

export default ContactInfoSection;

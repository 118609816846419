import React, { FC } from 'react';
import { formatAttributeName } from 'helpers/utils/formatAttributeName';

interface AttributesTableProps {
  attributes: string[];
}

const AttributesCategoryTable: FC<AttributesTableProps> = ({ attributes }) => {
  return (
    <table className="w-full table-fixed">
      <tbody>
        {Object.entries(attributes).map(([key, attribute]) => {
          const splittedValue = attribute.split(':');
          return (
            <>
              <tr key={attribute} className="border-b border-neutral-2 last:border-none">
                <td className="w-1/2 break-words border-r px-12 text-14">
                  {splittedValue[0] ?? formatAttributeName(key)}
                </td>
                <td className="w-1/2 px-16 py-12 text-left text-14">
                  <div key={attribute}>{splittedValue[1]}</div>
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};
export default AttributesCategoryTable;

function MadeInPolandCircleLabel({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20 * scale} height={20 * scale} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_606_47688)">
        <mask
          id="mask0_606_47688"
          style={{ maskType: 'alpha' }}
          width="20"
          height="20"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10s4.477 10 10 10 10-4.477 10-10z"></path>
        </mask>
        <g mask="url(#mask0_606_47688)">
          <path fill="#ED1B2E" d="M20 9.474h-38.947V20H20V9.474z"></path>
          <path fill="#fff" d="M20-.526h-38.947V10H20V-.526z"></path>
          <path stroke="#ED1B2E" d="M19.474 10A9.474 9.474 0 10.526 10a9.474 9.474 0 0018.948 0z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_606_47688">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default MadeInPolandCircleLabel;

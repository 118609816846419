import React, { FC } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import MadeInPolandCircleLabel from 'components/icons/labels/MadeInPolandCircleLabel';
import RoundedLabel from 'components/icons/labels/RoundedLabel';
import { ProductSummaryLabels } from './product-summary';

export interface LabelsSectionProps {
  labels: ProductSummaryLabels[];
  disabled?: boolean;
}

const LabelsSection: FC<LabelsSectionProps> = ({ labels, disabled }) => {
  const sortedLabels = labels
    .sort((a, b) => a.labelType.localeCompare(b.labelType))
    .filter((label) => label.labelValue !== '' && label.labelValue !== undefined);

  return (
    <div className="flex flex-col items-start gap-12 self-stretch pt-12">
      {sortedLabels.map((label, index) => (
        <React.Fragment key={label.labelType}>
          <div className={`flex items-center gap-8  ${disabled ? 'text-neutral-2' : 'text-neutral-4'}`}>
            {label.labelType === 'delivery' ? (
              <RoundedLabel variant="delivery" additionalClassName={disabled ? 'opacity-20' : ''} />
            ) : null}
            {label.labelType === 'freeShipping' ? (
              <RoundedLabel variant="freeShipping" additionalClassName={disabled ? 'opacity-20' : ''} />
            ) : null}
            {label.labelType === 'madeInPoland' ? (
              <span className={disabled ? 'opacity-20' : ''}>
                <MadeInPolandCircleLabel />
              </span>
            ) : null}
            <Typography className="text-16 font-bold leading-normal">{label.labelValue}</Typography>
          </div>
          {index !== sortedLabels.length - 1 ? <div className="w-full border-b border-neutral-2" /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LabelsSection;
